<template>
  <el-drawer
    :title="'Create Orders'"
    :visible.sync="shouldShow"
    append-to-body
    direction="rtl"
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <template>
            <el-row type="flex">
              <el-col :span="24">
                <subscription-configuration
                  :config="form.plan_details"
                  @set-config="setConfig"
                />
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Start date"
                  prop="start_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.start_date"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    placeholder="Select start date"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-center">
      <el-button type="primary" :loading="creating" @click="create"
        >Create Orders</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerOrdersCreate",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      creating: false,
      form: {
        plan_details: {},
        start_date: "",
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    show() {
      if (this.show) {
        const { next_billing_date } = this.customer;
        const plan = next_billing_date.length ? next_billing_date[0].plan : {};
        this.form.services = Object.keys(plan);
        this.form.plan_details = plan;
      }
    },
  },
  methods: {
    setConfig({ config }) {
      this.form.plan_details = config;
    },
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
      this.form.services = [];
      this.form.plan_details = {};
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const payload = {
          new_onboarding_flow: true,
          plan_details: JSON.stringify(this.sortObject(this.form.plan_details)),
          start_date: this.form.start_date,
        };
        this.creating = true;

        customer
          .ordersCreate(this.customer.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.creating = false;
              this.$emit("success");
              this.closeEvent();
            } else {
              this.$message.error(response.data.message);
              this.creating = false;
            }
          })
          .catch((error) => {
            this.creating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-customer-service {
  margin-bottom: 40px;
}
</style>
